import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-detalle-objeto',
  templateUrl: './detalle-objeto.page.html',
  styleUrls: ['./detalle-objeto.page.scss'],
})
export class DetalleObjetoPage implements OnInit {

  id:any;
  data_objeto:any = {};

  constructor( private _api: ApiService, private route: ActivatedRoute, public navCtrl: NavController ) { 
    this.id = this.route.snapshot.params.id;

    if(!this.id){
      this.navCtrl.navigateRoot("home/objetos");
    }

  }

  ngOnInit() {
    this.getObjeto();
  }

  getObjeto(){
    this._api.getObjeto( { imei: this.id } ).subscribe((data:any)=>{
      console.log( data );
      this.data_objeto = data.data[0];
    });
  }

  viewGoogleMaps(){
    window.open(`https://www.google.com.mx/maps?q=${this.data_objeto.lat},${this.data_objeto.lng}`);
  }
  


}
